import DateRangePicker from '../DateRange/DateRange.js';
import { ClearButton, FilterTextBox, FilterWrap } from './Filter.Styled.js';

export default function ArchiveSearch({
  searchTerm,
  handleInputChange,
  handleSubmit,
  handleClear,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  return (
    <>
      <FilterWrap>
        <FilterTextBox
          placeholder="bills, ids"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleSubmit}
        />
        <DateRangePicker
          onDateChange={() => {}}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <ClearButton onClick={handleClear}>Clear</ClearButton>
        {/*<S.SortButtons style={{marginLeft: "auto"}}>
          <img src={sortImg} height="30px" alt="sort by" />
          <S.ToggleButton
            active={sortBy === "title"}
            onClick={() => setSortBy("title")}
          >
            Title
          </S.ToggleButton>
          <S.ToggleButton
            active={sortBy === "chrono"}
            onClick={() => setSortBy("chrono")}
          >
            Date
          </S.ToggleButton>
        </S.SortButtons>*/}
      </FilterWrap>
    </>
  );
}
