import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import * as S from "./Story.Style";

import {ModalVideoPanel} from "../ModalPopUp/ModalPopUp";
import {ModalVideoPanelBullet} from "../ModalPopUp/ModalPopUp";
import VideoTranscriptModal from "./VideoTranscriptModal";
import ModalUI from "../../UI/Modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Story.css";
import playIcon from "../../assets/images/play10up.svg"; //"../../assets/images/black-play-icon.png";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {useLocation, useHistory, Link} from "react-router-dom";
// import __html from "../../assets/CA_201720180SB649_48_54100_31961v7.json";
import a from "../../assets/Gallery/image-2.jpg";
//import b from "../../assets/Gallery/image-3.jpg";
import TipSheet_Vote from "../TipSheet/TipSheet_Vote";
import TipSheet_Participant from "../TipSheet/TipSheet_Participation";
import TipSheet_Topic from "../TipSheet/TipSheet_Topic";
import TipSheet_Presenter from "../TipSheet/TipSheet_Presenter";
import TipSheet_Others from "../TipSheet/TipSheet_Others";
import TipSheet_Quotes from "../TipSheet/TipSheet_Quotes";
import TipSheet_General from "../TipSheet/TipSheet_General";
import TipSheet_Bill_Intro from "../TipSheet/TipSheet_Bill_Intro";
import TipSheet_Summary from "../TipSheet/TipSheet_Summary";
import TipSheet_InfoGauges from "../TipSheet/TipSheet_InfoGauges";
import Calendar from "../Chart/Calendar";
import TipSheetNotes from "../TipSheet/TipSheetNotes";
import Sidebar from "../Sidebar/Sidebar";
import ChartTable from "../Chart/ChartTable";
import ChartPie from "../Chart/ChartPie";
import ChartGauge from "../Chart/ChartGauge";
import StackedBar from "../Chart/StackedBar";
import AlignmentMeter from "../Chart/AlignmentMeter";
import BarFormatTable from "../Chart/BarFormatTable";
import BarChart from "../Chart/BarChart";
import TreeMap from "../Chart/TreeMap";
import Sankey from "../Chart/Sankey";
import tags from "../../assets/Tags";
import { null_phenoms } from "../Stories/Stories";
import { getTriggers } from "../Stories/Stories";
import { getDuration } from "../Stories/Stories";
import { getAnnotatedScore } from "../Stories/Stories";
import { render_cards } from "../Stories/Stories";
import { tagGraphics } from "../Stories/Stories";
import {voteOutcome} from "../Stories/Stories";
import {getAuthor} from "../Stories/Stories";
import { LinkDD, LinkSimple } from "../Stories/Links";
import { LinkFormatColumn } from "../Stories/Links";
import AlignmentMeters from "./AlignmentMeters";
import {DefaultDict} from "../util/defaultDict.js";
import Expandable from "../Expandable/Expandable.js";
import Markdown from 'react-markdown';
import SliderSankey from "../Chart/SliderSankey.js";

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
const allJSON = [];
const title_store = [];
//const alignmentMeterData = [];
//const relevantOrgs = [];
const videoStore = [];
const photoStore = [];
const orgInterestStats = {'q1':0, 'q2':0, 'q3':6, 'mean':4.62, 'median':0,'std_dev':9.31,'max_display':20}
// a number of fixes mainly in the text narrative of phenoms
// should be ultimately done during phenom generation
const temp_fixes = [
      ['Democrat members', 'Democratic members'],
      ['Democrat party', 'Democratic party'],
      ['good questions', 'questions'],
]

// function that applies above replacements
function replaceTempFixes(inString) {
    //console.log('replaceTempFixes:',inString);
    let newString = inString;
    temp_fixes?.forEach((replacement) => {
      newString = newString.replace(replacement[0],replacement[1])
    })
    return newString;
}

// replaces legislator codes like __755 with actual first and last names from personas
// returns the modified string
export function replaceLegCodes(inString, personas) {
    const replaceList = inString.match(/__[0-9]+/g);
    let new_version = replaceTempFixes(inString);
    replaceList?.forEach((item) => {
      const leg_id = Number(item.substring(2));
      personas.forEach((persona) => {
        if (leg_id === persona['pid']){
          new_version = new_version.replace(item,
              LinkDD(persona['first'].trim()+' '+persona['last'].trim(),
                  leg_id,'leg',leg_id,true));
        }
      })
    })
    //return replaceTempFixes(new_version);
    return (<span dangerouslySetInnerHTML={{ __html: new_version }} />);
}

export default function Story(props) {
  //The useLocation hook returns the location object that represents the current URL.
  const [showTooltip, setShowTooltip] = useState(null);

  let Story = useLocation();
  const [tipsheet_type, setTipsheet_type] = useState(1);
  const [currentVideoType, setCurrentVideoType] = useState('normal');
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const [quoteVideo, setQuoteVideo] = useState([]);
  const [currentVideoURL, setCurrentVideoURL] = useState('');
  const [transcriptText, setTranscriptText] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [tipsheetHtml, setTipsheetHtml] = useState({
    "tip_title": "",
    "tip_headline": "",
    "trigger_reasons_header": "",
    "trigger_reasons_text": "",
    "tag_items": [],
    "summary_vitals": ""
  });

  // UseState Hooks to store data coming over RestAPI Call
  const [aStory, setAStory] = useState([]);
  const [aTable, setATable] = useState([]);
  const [aTableCaptions, setATableCaptions] = useState([]);
  const [triggerText, setTriggerText] = useState([]);
  const [Personas, setPersonas] = useState([]);
  const [aPie, setAPie] = useState([]);
  const [aChartPie, setAChartPie] = useState([]);
  const [alignmentMeterData, setAlignmentMeterData] = useState([]);
  const [contributionsTreeMapData, setContributionsTreeMapData] = useState([]);
  const [alignmentContributionTableData, setAlignmentContributionTableData] = useState([]);
  const [alignmentContributionTableTitle, setAlignmentContributionTableTitle] = useState([]);
  const [supportersOpponentsData, setSupportersOpponentsData] = useState([]);
  const [sankeyFinancialData, setSankeyFinancialData] =  useState([]);
  let supportersOpponentsChartRender = useRef(null);
  const [relevantOrgs, setRelevantOrgs] = useState([]);
  const vidPlayer = useRef(null);
  const transcript = useRef(null);
  const linkExample = "https://digitaldemocracy.calmatters.org";
  const [votePartyData, setVotePartyData] = useState([]);
  const [infoGaugeData, setInfoGaugeData] = useState([]);

  // AlignmentMeterComponent
  const [alignmentMeterInfo, setAlignmentMeterInfo] = useState([]);
  const [alignmentMeterOrgs, setAlignmentMeterOrgs] = useState([]);
  const [billDiscussionLinkInfo, setBillDiscussionLinkInfo] = useState([]);
  //new function by Thomas G. to be backwards compatible with old style naming.
  function check_story_id(id) {
    let tipsheet_id_fields = id.split("_")
    if (tipsheet_id_fields.length > 4 && tipsheet_id_fields[4][0] !== 'T') {
      return tipsheet_id_fields[0].concat(
        "_", tipsheet_id_fields[1], "_", tipsheet_id_fields[3], "_", tipsheet_id_fields[4],
      )
    } else{
      return id
    }
  }
  // const APIPathStory = Story.pathname.split("/")[1];
  const APIPathStory = check_story_id(Story.pathname.split("/")[1]);
  // API Call End Point URL for server
  // const API_URL = `http://ai4reporters.org:8002/api/${APIPathStory}`;
  const API_URL = `${process.env.REACT_APP_FEATURED_API}`+`${APIPathStory}`;

  // API Call End Point URL for server
  //const API_URL = `http://127.0.0.1:8002/api/${APIPathStory}`;

  // handle redirects upon expired token API requests
  let location = useLocation();
  const history = useHistory();
  const handleExpiredToken = () => {
    console.log("Expired session token, redirecting to login.")
    localStorage.removeItem('token');
    history.push({
      pathname: '/login',
      state: { from: location }
    });
  };

  // fetchData function is used to get JSON from Backend
  const fetchData = () => {
    let axiosT = axios.create({
      baseURL: `${API_URL}`,
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`
      }
    });
    axiosT
      .get("/")
      .then((response) => {
        allJSON.push(response.data);
        videoURLParser(response);
        CheckTipBoxes(Number(response.data?.video_duration));
        HandleTriggerText(response.data);
        CheckAlignmentMeter(response.data.assets);
        FormData(response);
        //CreateTipSummary(response.data.tipsheet_html);
        setAStory(response.data);
        if(aStory.data?.tipsheet_type === "bill") {
          setTipsheet_type(2);
          //console.log('BILL TYPE, all response: ',response);
        }

      })
      .catch((err) => {
        console.log(err);
        handleExpiredToken();
      });
  };
  // console.log('tipsheet type~ ',tipsheet_type);
  // console.log('All response: ',aStory);
  // videoURLParser function is used to get data for Video URLs from Backend
  function videoURLParser(item) {
    if (item.data.assets)
      item.data.assets.map((element) =>
        element.asset_type === "video" ? videoStore.push(element.url) : null
      );
  }


  const handleVideoExtracted = (url) => {
    setQuoteVideo(prevVideos => [...prevVideos, url]);
  };

  function personaArrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele.toLowerCase() !== value.toLowerCase();
    });
  }

  function personaArrayLobbyistFilter(arr) {
    return arr.filter(function (ele) {
      return ele.toLowerCase() === 'lobbyist'
    });
  }

  // replaces legislator codes like __755 with actual first and last names from personas
  // returns the modified string
  function replaceLegCodes3(inString){
    const replace = inString.match(/__[0-9]+/g);
    //console.log("replacers: ",replacers);
    let new_version = replaceTempFixes(inString);
    replace?.forEach((item) => {
      const leg_id = Number(item.substring(2));
      allJSON[0]['personas']?.forEach((persona) => {
        //console.log('persona: ',persona);
        if (leg_id === persona['pid']){
          new_version = new_version.replace(item,
            //persona['first']+' '+persona['last']);
              LinkDD(persona['first'].trim()+' '+persona['last'].trim(),leg_id,'leg',leg_id,true));
        }
      })
    })
    //console.log("new version: ",new_version);
    //return replaceTempFixes(new_version);
    return (<div dangerouslySetInnerHTML={{ __html: new_version }} />);
  }

  function stringify(arr) {
    let final = ""
    arr.forEach((item) => {
      final += item
      final += ', '
    });
    return final.slice(0, -2);
  }

  function CreateTipSummary(tsh) {
    let parser = new DOMParser();
    if (tsh) {
      tsh = tsh.replaceAll("class='trigger_reasons_text'", "class='trigger_reasons_text").replaceAll("class='trigger_reasons_text", "class='trigger_reasons_text'");//.replaceAll('> \n','>').replaceAll('>\n','>');
      let doc = parser.parseFromString(tsh, 'text/html');
      let htmlObject = {
        "tip_title": "",
        "tip_headline": "",
        "trigger_reasons_header": "",
        "trigger_reasons_text": "",
        "tag_items": [],
        "summary_vitals": ""
      }

      htmlObject.tip_title = doc.body.firstElementChild.firstElementChild.firstChild.nextSibling.innerText;
      let summeryHtml = doc.body.firstElementChild.firstElementChild.firstElementChild.nextElementSibling;
      htmlObject.tip_headline = summeryHtml.getElementsByClassName("tip_headline")[0].innerText;
      htmlObject.trigger_reasons_header = summeryHtml.getElementsByClassName("trigger_reasons_header")[0].innerText;
      htmlObject.trigger_reasons_text = summeryHtml.getElementsByClassName("trigger_reasons_text")[0].innerText;
      htmlObject.summary_vitals = summeryHtml.getElementsByClassName('summary_vitals')[0].getElementsByTagName("ul")[0].outerHTML;
      let tag_itemsHtml = summeryHtml.getElementsByClassName("tag_item");
      if (tag_itemsHtml) {
        for (var i = 0; i < tag_itemsHtml.length; i++) {
          htmlObject.tag_items.push(tag_itemsHtml[i].innerText);
        }
      }
      setTipsheetHtml(htmlObject)
    }
  }

  function getVitals(ts_json) {
    return(
        <h2>On {ts_json.hearing_date}, the {ts_json.committee} held a session, to discuss {ts_json.bill_name} covering subjects: {ts_json.bill_subject}</h2>
    )
  }

  function findElementRow(array, vote) {
    return array.find((element) => {
      return element['Vote'] === vote;
    });
  }

  function getVotePartyData(assetTable){
    // headers in assetTable are ['Legislator','Vote','Party','District']
    // headers in output need to be: ["Vote", "Democratic", "Republican","Other",{ role: "annotation" }],
    var return_data = [["Vote", "Democratic", "Republican","Other",{ role: "annotation" }]];
    const temp_data = [
      {'Vote':'AYE','Democratic':0,'Republican':0,'Other':0,'annotation':''},
      {'Vote':'NO','Democratic':0,'Republican':0,'Other':0,'annotation':''},
      {'Vote':'NVR','Democratic':0,'Republican':0,'Other':0,'annotation':''},
    ];

    assetTable.forEach((item) => {
      let vote=item[1].toUpperCase();
      let party = item[2];
      if ((party.substring(0,3)).toLowerCase() === 'rep') {
        party = "Republican";
      } else
      if ((party.substring(0,3).toLowerCase()) === 'dem') {
        party = "Democratic";
      } else {
        party = "Other";
      }

      let assetRow = findElementRow(temp_data, vote);
      findElementRow(temp_data,vote)[party] = assetRow[party] + 1;
      findElementRow(temp_data,vote)['annotation'] = assetRow[party];
    });

    temp_data.forEach((item) => {
      return_data.push([item['Vote'],item['Democratic'],item['Republican'],item['Other'],
        (item['Democratic'] + item['Republican'] + item['Other']).toString(),
      ]);
    });
    //console.log("return getVotePartyData(): ", temp_data, return_data);
    return return_data;
  }

  function handleAlignmentContributionsTable(assetTable,title,caption,index_table,org_index,org_id_index) {
    /*
            format/order of the alignmentContributionTableData

            0:"pid"
            1:"full_name"
            2:"first"
            3:"last"
            4:"party"
            5:"alignment_score"
            6:"alignment_opp_count"
            7:"legislator_vote"
            8:"name"
            9:"org_position"
            10:"advocating_as"
            11:"donating_as"
            12:"donations"
            13:"contribution_time_period"
            14:"org_success_rate"
            15:"total_donated"
            16:"alignment_time_period"
            17:"total_donated_time_period"
            18:"org_position_date"
            19:"org_success_rate_time_period"

    */
    assetTable = assetTable.map((row, index) => {
      if (index === 0){
        row[1] = "Legislator";
        row[2] = "Party";
        row[4] = "Vote";
        row[5] = {type: "number", label: "Alignment Score (based on # of votes) over [period]"};
        row[9] = "Org Position";
        row[12] = "Contributions [period]";
        row[13] = "Period";
        row[14] = "Org Success (%)";
        row[15] = "Contribs. to all";
        row[16] = "Alignment Period";
        if (row.length > 17) {
          row[17] = "Total Contributions Period";
          row[18] = "Org Position Date";
          row[19] = "Success Rate Period";
        }
      }
      else{
        row[1] = {v:row[3]+', '+row[2],
          f:LinkDD(row[1],row[0],'leg',null,true)};
        row[2] = row[4].substring(0,3);
        row[4] = row[7];
        let voteVal = 0;
        if (row[4] === "ABS") {
          row[4] = "NVR";
          voteVal = 2;
        }
        else if (row[4] === "NOE"){
          row[4] = "NO";
          voteVal = 1;
        }
        row[4] = {v:voteVal, f:row[4]};
        if ((typeof row[5] !== 'string' || typeof row[6] !== 'string'))
        {
          row[5] = {v: row[5]-50, f: Number(row[5].toFixed(1)).toString() +
                '% (' + row[6].toString() + ') ['+ row[16]+']'};
        }
        else row[5] = {v:'',f:'NO RECORD'};

        if (typeof row[12] == 'string') {
          row[12] = {v:'',f:'NO RECORD'};
        } else {
          /* example link to financial directory
          * https://digitaldemocracy.calmatters.org/financials?
          * receiver%5B%5D=pid-30
          * &giver%5B%5D=oid--2213
          * &transaction_type%5B%5D=candidate_donations
          * &year%5B%5D=2015
          * &year%5B%5D=2016
          * &year%5B%5D=2017
          * &year%5B%5D=2018
          * &year%5B%5D=2019
          * &year%5B%5D=2020
          * &year%5B%5D=2021
          * &year%5B%5D=2022
          * &transaction_type%5B%5D=candidate_donations
          * &transaction_type%5B%5D=party_committees
          * ------
          * LinkSimple(inText,inUrl,inKey = null, returnText = true)
          * */
          let year_segments = row[13].toString().split(' to ');
          let start_year = parseInt(year_segments[0],10);
          let end_year = parseInt(year_segments[1],10);
          let url = 'https://digitaldemocracy.calmatters.org/financials?receiver%5B%5D=pid-'
              +row[0].toString()
              +'&transaction_type%5B%5D=candidate_donations'
              +'&transaction_type%5B%5D=party_committees'
              +'&giver%5B%5D=oid-'+row[8]['org_id'].toString()
              +(range(start_year,end_year+1).map(y => '&year%5B%5D='+y.toString()).join(''));
          row[12] = {
            v:row[12],
            f:LinkSimple('$'+row[12].toLocaleString(),url,row[0].toString()+row[8]['org_id'].toString())
                +' ['+row[13].toString()+']'
          };
        }
        let successPeriod = "";
        if (row.length > 19 && row[19])
          successPeriod = "["+row[19]+"]";
        row[14] = {v:Number(Number(row[14]).toFixed(1)),f:(Number(row[14]).toFixed(1).toString()+'% '+successPeriod)};

        let totalContribPeriod = "";
        if (row.length > 17)
          totalContribPeriod = "["+row[17]+"]";

        if (typeof row[15] === 'string' && row[15] === 'N/A')
          row[15] = "NO RECORD";
        else
          row[15] = {v:row[15], f:'$'+row[15].toLocaleString(undefined, {maximumFractionDigits:2})+ ' '+totalContribPeriod};

        //FYI. At this point, row[8] is already an object that contains the oid too
        // and row[8]'s "value" must be exactly the same as what we want to show up in the drop-down selector, no way around it
        if (row[8] && row[8].hasOwnProperty('v')) {
          row[8]['v'] = row[8]['v'] + ' | Success: ' + row[14]['v'].toString()+'% ' + ' | Position: '+row[9];
        }
        else row[8] = "N/A";

        if (row.length > 18){
          row[9] += ' (recorded on '+row[18]+')';
        }
      }
      return row;
    });

    let sankeyView = assetTable.filter(row => row[12]['v'] > 0).map((row, index) => {
      return ([
        row[8]['v'].split(' |')[0],
        {v:row[1]['v'].split(',')[0]},
        row[12]['v'],
        row[8]['v'].split(' |')[0] + ' total donations to ' + row[1]['v'].split(',')[0]
        +': <b>$' + row[12]['v'].toLocaleString()+'</b> ['+row[13].toString()+']',
      ])
    });
    if (sankeyView.length > 0) {
      sankeyView.unshift(['Organization','Legislator','Amount ($)',
        {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}])
      setSankeyFinancialData(sankeyView);
    }


    // Add NULLED Alignment values for superset of Legislators
    let addedLegislators = new Map();
    let orgs = new Set();
    let legislatorOrgs = new DefaultDict(() => new Set());

    assetTable.slice(1).forEach((row) => {
      if (!addedLegislators.has(row[1]['v'])) {
        let tmpData = [...row]
        tmpData[5] = {v: null, f: "NA"}
        tmpData[8] = null 
        addedLegislators.set(row[1]['v'], tmpData)
      }
      legislatorOrgs.get(row[1]['v']).add(row[8]['v'])
      orgs.add(row[8]['v'])
    })

    // for each org, add legislators that don't have an alignment value with the org as a data point
    // so they will still appear in the table
    orgs.forEach((org) => {
      Array.from(addedLegislators.keys()).forEach((leg) => {
        if (!(legislatorOrgs.get(leg).has(org))){
          let tmp = [...addedLegislators.get(leg)]
          tmp[8] = org
          assetTable.push(tmp)
        }
      })
    })
    let new_caption = <><p>The drop-down menu allows you to choose one of the organizations that took a position on
      this bill to see data about their relationship to each legislator who cast a vote. In the drop-down menu, you
      will see the organization’s position on this specific bill, its overall success rate in passing its agenda
      through the Legislature during the designated time period and the total mount of money (identified by Digital
      Democracy at the time of Tip Sheet publication) this organization has donated to legislators.
      donated to legislators.</p>
      <p>The chart displays the relationships between legislators involved in this vote and interest groups that took
        a position on this bill. It shows the legislator’s name and how they voted. It shows an “alignment score,”
        which is how often this legislator’s vote and positions taken by this organization have been identical during
        the designated time period. The alignment bar indicates red for less than 50% alignment and blue for more
        than 50%. The chart also shows any financial contributions from this organization to this legislator identified
        by Digital Democracy at the time of the Tip Sheet publication.</p></>
    ;
    //console.log('asset Table: ', assetTable);
    setAlignmentContributionTableData(assetTable);
    setAlignmentContributionTableTitle(['Legislator-Interest Group Relationships', new_caption]);
  }

/*function getPosition(pid,hearing_transcript){
  const utterances = hearing_transcript.filter(item => pid === item.speaker_pid)
      .map(utterance => utterance.alignment?.toLowerCase());
  if (utterances?.length > 0) {
    const position = utterances[0];
    console.log('getPosition: ', position);
    return(position);
  }
  return null;
}*/
  function infoGaugePrep(){
    let gaugeData = [];
    if (aStory.tipsheet_type === "bill")
      return;
    let v = aStory.hearing_transcript
        ?.map(utterance => utterance['end_time'] - utterance['start_time'])
        ?.reduce((a,b) => a = a + b)
        /60.0;
    if (v > 0) v = Math.round(v * 100)/100;

    if (Personas && Personas.length) gaugeData.push(
        { 'title':'testimonies', 'noun_prefixToActivity':'testimony','value_suffix':'','value_prefix':'',
          'value':Math.max(Personas.length-1,0), 'valueUnits':'non-legislator witnesses spoke',
            'floorCheck':aStory.committee?.toLowerCase().includes('floor'), 'floorMessage':'Floor sessions have no witnesses',
            'q1':2, 'q2':5, 'q3':9, 'mean':7.55, 'median':5,'std_dev':11.76,
            'max_display':20,
        }
    );
    if (alignmentContributionTableData.length > 1) {
      gaugeData.push({ 'title':'org. interest', 'noun_prefixToActivity':'org. interest','value_suffix':'','value_prefix':'',
          'value':[...new Set(alignmentContributionTableData
                              .slice(1)
                              .map(item => item[8]['org_id']))]
                              .length,
            'valueUnits':'organizations that took a position',
            'floorCheck':false, 'floorMessage':'',
            'q1':0, 'q2':0, 'q3':6, 'mean':4.64, 'median':0,'std_dev':9.31,
            'max_display':20,
      });
      gaugeData.push({ 'title':'$donations', 'noun_prefixToActivity': 'donations',
        'value_suffix':'K',
        'value_prefix':'$',
          'value':Math.round(alignmentContributionTableData
                              .slice(1)
                              .map(item => (item[12]['v'] === 'N/A') ? 0: Number(item[12]['v']))
                              .reduce((a,b) => a = a + b,0)/1000),
            'valueUnits':'donated to committee members by organizations with a position on this discussion',
            'floorCheck':false, 'floorMessage':'',
            'q1':0, 'q2':0, 'q3':49, 'mean':230.14, 'median':0,'std_dev':990.13,
            'max_display':250,
      });
    }
        /*{ 'title':'debate time', //'value':5,
          'value':v,
            'valueUnits':'minutes',
            'floorCheck':false, 'floorMessage':'Floor sessions have little debate',
            'q1':0.87, 'q2':1.53, 'q3':3.23,
            'mean':3.12, 'median':1.53,'std_dev':4.89,
            'max_display':20,
        },*/
    return(gaugeData);
  }

  // FormData function is used to get data for Table and Pie Chart index on tipsheet_html_segments from Backend
  function FormData(item) {
    //const tipSheetHtmlSegments = item.data.tipsheet_html_segments;
    const assetType = [];
    if (item.data.assets)
      item.data.assets.map((item) => assetType.push(item.asset_type));
    // const index_table = assetType.indexOf("table");
    const index_table_list = assetType
      .map((entry, i) => (entry === "table" ? i : -1))
      .filter((idx) => idx !== -1);

    const rawPersonas = item.data.personas;
    const transcript = item.data.hearing_transcript;
    const hearing_id = item.data.jsonid?.split('_')[2];
    const index_pie = assetType.indexOf("pie_chart");
    const assetPie =
      index_pie >= 0
        ? // The fix is done by making the object mappable with object.entries
          Object.entries(item.data.assets[index_pie].data).map(Object.values)
        : null;

    const personaTitle = [
      "Speaker",
      "Role",
      "Affiliations",
      "Position",
      "⏱",
      "🔗"
    ];

    // save this to construct a link to DD hearing pages
    if (transcript) //.constructor === Object)
    setBillDiscussionLinkInfo(
        transcript?.filter((utterance, index) => index === 0)
          .map(utterance => {
            return {hearing_id:hearing_id,
                    offset: utterance.start_time,
                    file_id: utterance.vid_file_id,}
          })[0]
    );

    //console.log("index pie = ",index_pie)
    //filtering personas
    let cleanPersonas = [];
    if (rawPersonas) {
      rawPersonas.forEach((item) => {
        if (!item['p_type'].includes('Legislator') &&
            item['first'] !== 'Reading' &&
            item['first'] !== 'Unidentified') {
          let temp = []
          const transcriptInfo = transcript?.filter(utterance => item['pid'] === utterance.speaker_pid);
          const speakerDuration = transcriptInfo
              .map(line => line.end_time - line.start_time)
              .reduce((a, v) => a = a + v, 0);

          temp.push({
            v: item['last'] + ', ' + item['first'],
            f: LinkDD(item['first'] + ' ' + item['last'], item['pid'], 'per', item['pid'], true)
          });

          if (item['p_type'].includes('Lobbyist')) {
            item['p_type'] = ['registered lobbyist'];
          }

          if (item['p_type'].includes('General Public')) {
            item['p_type'] = ['advocate'];
          }

          if (item['affiliation'].includes('None')) {
            item['affiliation'] = ['[none recorded]'];
            item['p_type'] = ['general public'];
          }

          if (Array.isArray(item['affiliation'])) {
            item['affiliation'] = item['affiliation'].join(", ");
          }

          temp.push(stringify(item['p_type']));
          temp.push(item['affiliation']);

          item['position'] = (transcriptInfo?.length > 0) ? transcriptInfo[0].alignment : 'undetermined';
          let voteVal = 2;
          if (item['position'].includes('_')) {
            voteVal = 2;
          } else if (item['position'].slice(0, 3).toLowerCase() === "for") {
            voteVal = 0;
          } else if (item['position'].slice(0, 7).toLowerCase() === "against") {
            voteVal = 1;
          }
          temp.push({v: voteVal, f: item['position']});

          item['time'] = (transcriptInfo?.length > 0) ? {f: getDuration(speakerDuration), v: speakerDuration} : 'N/A';
          item['link'] = (transcriptInfo?.length > 0) ?
              {
                f:
                    ' <span class="no-decoration">' +
                    LinkDD("⏩", hearing_id, 'hea', null, true, null,
                        '?t=' + transcriptInfo[0].start_time + '&f=' + transcriptInfo[0].vid_file_id) +
                    '</span>',
                v: hearing_id
              }
              : 'N/A';
          item['participation'] = (transcriptInfo?.length > 0) ?
              {
                f: getDuration(speakerDuration) +
                    ' <span class="no-decoration">' +
                    LinkDD("⏩", hearing_id, 'hea', null, true, null,
                        '?t=' + transcriptInfo[0].start_time + '&f=' + transcriptInfo[0].vid_file_id) +
                    '</span>',
                v: speakerDuration
              }
              : 'N/A';
          temp.push(item['time']);
          temp.push(item['link']);

          cleanPersonas.push(temp);
        }
      });
    }

    if(cleanPersonas.length > 0) {
      cleanPersonas = cleanPersonas.toSorted((a, b) => b[4]['v'] - a[4]['v']);
      cleanPersonas.unshift(personaTitle);
      //console.log('personas: ',cleanPersonas);
    }
    setPersonas(cleanPersonas);

    index_table_list.forEach((index_table) => {
      //early exit for null tables
      if (!item.data.assets[index_table].hasOwnProperty('data') ||
          !item.data.assets[index_table].hasOwnProperty('header') ||
           item.data.assets[index_table].data.length === 0) {
        return;
      }

      var title_copy = item.data.assets[index_table].header;
      var assetTable_copy = item.data.assets[index_table].data.map(Object.values);
      var assetTable_3 = item.data.assets[index_table].data;
      var assetID = item.data.assets[index_table]['id'];
      var caption = item.data.assets[index_table].caption;

      var assetTable = assetTable_copy;
      var title;

      const org_index_syns = ['orgid','oid','oc_id','org id'];
      // checking to see if there are any "orgID"/"Organization" column name pairs
      // or "org id"/"Name" as it is in one case
      // if there are, we need to know their index numbers.
      let org_index = title_copy.findIndex((e) => e.toLowerCase() === "organization");
      let org_id_index = title_copy.findIndex((e) => org_index_syns.includes(e.toLowerCase()));
      //let org_id_index = title_copy.findIndex((e) => e.toLowerCase() === "orgid");
      if (org_id_index < 0)
        org_id_index = title_copy.findIndex((e) => e.toLowerCase() === "org id");
      if (org_id_index < 0)
        org_id_index = title_copy.findIndex((e) => e.toLowerCase() === "oid");
      if (org_index < 0 && org_id_index >= 0)
        org_index = title_copy.findIndex((e) => e.toLowerCase() === "name");

      let modifyOrg =  (org_index >= 0 && org_id_index >= 0);

      let pidIndex = title_copy.findIndex((e) => e.toLowerCase() === "pid");

      if (assetID === "vote_detail") {  //trying to fix bad order
        title_copy.shift();
        // Foaad: Should be rearranged on the back-end
        title_copy = ['Legislator','Vote','Party','District'];
        assetTable = assetTable_copy.map(row => {
          // Check if the row has at least 6 elements
          if (row.length >= 6) {
            let member_name = row[3]+', '+row[1];
            let vote = row[6];
            if (vote === "NOE") //fix major misspelling
              vote = "NO";
            if (vote === "ABS")
              vote = "NVR";
            // console.log("district:",row[5]);
            // LinkDD(member_name,Number(row[0]),'leg',Number(row[0])).toString()
            // '<a href="https://digitaldemocracy.calmatters.org/legislator/'+row[5]+'">'+member_name+'</a>'
            return [{
              v:member_name,
              f:LinkDD(row[1]+' '+row[3],Number(row[5]),'leg', Number(row[5]),true)
              },
              vote, row[4], row[2]+'-'+row[0]];
          } else {
            // Handle rows with fewer than 7 elements, if necessary
            // For example, return the row as is or handle it differently
            return row;
          }
        });
        // foaad: while we are here we derive the data for the StackedBar Chart of votes
        setVotePartyData(getVotePartyData(assetTable));
      }
      else { // handle all other tables, reset the order of items to the same order as the labels
        assetTable = assetTable_3.map((row) => {
          const newRow = [];
          title_copy.forEach((t) => {
            // we will do the linking if there is both org and org_id columns, and org_id < 0
            if (modifyOrg &&
              title_copy.indexOf(t) === org_index &&
                Number(row[title_copy[org_id_index]]) < 0){
              newRow.push({
                v:row[t],
                f:LinkDD(
                    row[t],
                    Number(row[title_copy[org_id_index]]),
                    'org',
                    Number(row[title_copy[org_id_index]]),
                    true
                ),
                org_id: Number(row[title_copy[org_id_index]])
              });
            }
            else if (modifyOrg &&
              title_copy.indexOf(t) === org_index) {
              newRow.push({v:row[t],f:row[t]});
            }
            else {
              // skips the "org ID" column values
              if (!modifyOrg || (title_copy.indexOf(t) !== org_id_index)) {
                if (isNaN(row[t]))
                  newRow.push(row[t]);
                else
                  newRow.push(Number(row[t]));
              }
            }
          });
          return newRow;
        });
        if(org_id_index >= 0){
          title_copy = title_copy.filter(item => title_copy.indexOf(item) !== org_id_index);
        }
      }

      /*title = title_copy.map((item) => {
        return item;
      });*/
      title = title_copy;
      assetTable.unshift(title);

      //if (true || !caption.includes('Organization Alignments') && item.data?.id !== "org_align_data")
      // various title corrections and subtitles
      {
        // foaad: a series of corrections that should all be handled at TS generation time
        if(assetID === "oid_pid_contribution_detail"){
          caption = ['Recent Contributions to Legislators',caption];
          assetTable[0][4] = "Amount ($)";
        }
        else if(assetID === ("oid_total_contribution_detail")){
          caption = ['Recent Total Contributions',caption];
          assetTable[0][1] = "Total Contributions ($)";
        }
        else if(assetID === ("oid_pid_committee_contribution_detail")){
          caption = ['Recent Contributions to Key Members',caption];
          assetTable.forEach((row, index) => {
            let first = row[1];
            let last = row[2];
            item.data.personas.forEach((persona) => {
              //console.log("Personas replace: ",persona,row,assetTable[index][2]);
              if (persona.first === first && persona.last === last){
                assetTable[index][2] = LinkDD(assetTable[index][1] + ' ' +assetTable[index][2],
                    persona.pid,'leg',persona.pid,true,assetTable[index][2]);
              }
            })
          })
          assetTable[0][5] = "Amount ($)";
        }
        else if(assetID === "vote_detail"){
          caption = ['Votes in This Discussion',caption];
        }
        else if(assetID ==="supporters_and_opponents_count_est"){
          //set the table for the BarChart Asset here
          let tableData = [
            ["", assetTable[0][0], { role: "annotation" }, assetTable[0][1],{ role: "annotation" }],
            ["Advocates", assetTable[1][0], assetTable[1][0], assetTable[1][1], assetTable[1][1]],
          ];
          setSupportersOpponentsData(tableData);
          return;
        }
        else if(assetID === "geo_concepts_table"){
          caption = [caption,"This Tip Sheet is associated with region(s) on the strength of terms listed below."];
          title_store.push(caption)
        }
        else if(assetID === "latest_bill_support_opposition") {
          caption = ["Organization Positions on this Bill",caption]
          assetTable[0][0] = 'Organization';
          assetTable[0][1] = 'Position';
          assetTable[0][2] = 'Date';
          assetTable[0][3] = 'Source';
          assetTable[0][4] = 'Contrib. to Legislators';
          assetTable[0][5] = 'Contrib. to Governor';
          assetTable.slice(1).forEach((row,index) => {
            assetTable[index+1][3] =  (row[3] === 1) ?  "Bill Analysis" : "Testimony";
            assetTable[index+1][4] = (typeof assetTable[index+1][4] === 'string'
                && assetTable[index+1][4] === "NO RECORD") ? {v:0, f:"NO RECORD"} :
                {v:Number(assetTable[index+1][4]), f:'$'+assetTable[index+1][4].toLocaleString(undefined, {maximumFractionDigits:2})
                      +' ['+assetTable[index+1][5] + ']'}
            assetTable[index+1][5] = (typeof assetTable[index+1][6] === 'string'
                && assetTable[index+1][6] === "NO RECORD") ? {v:0, f:"NO RECORD"}  :
                {v:Number(assetTable[index+1][6]), f:'$'+assetTable[index+1][6].toLocaleString(undefined, {maximumFractionDigits:2})
                      +' ['+assetTable[index+1][7] + ']'}
            //if (typeof row[15] === 'string' && row[15] === 'N/A') row[15] = "NO RECORD";
            //else row[15] = {v:row[15], f:'$'+row[15].toLocaleString(undefined, {maximumFractionDigits:2})
          });
          //get rid of last two columns
          assetTable = assetTable.map(row => row.slice(0,6));
          //assetTable[0] = assetTable[0].map(item => item.split('_').map(segment => segment[0].toUpperCase() + segment.slice(1)).reduce((acc, x) => acc === null ? [x] : [acc, ' ', x], null));
          title_store.push(caption)
        }
        else if(assetID === "bill_vote_history"){
          caption = ["Previous Votes on this Bill", caption];
          assetTable[0] =['Location','Vote Date','Motion','Ayes','Noes','NVRs','Outcome'];
          title_store.push(caption);
        }
        else if(assetID === "vote_alignment_org_contribution_success"){
          handleAlignmentContributionsTable(assetTable,title_copy,caption,index_table, org_index,org_id_index);
          return;
        }
        else {
          caption = [caption,'']
        }
        aTable.push(assetTable);
        aTableCaptions.push(caption);
      }
    });
    setATable(aTable);
    setATableCaptions(aTableCaptions);



    // trying to manipulate data for piechart
    // assetPie.unshift([assetPie[0]])

    if (assetPie !== null) {
      //console.log("index pie, assetPie ",index_pie, assetPie)
      let new_assetPie = [Object.keys(assetPie[0][1])];
      assetPie.forEach((item) => {
        new_assetPie.push(Object.values(item[1]));
      });
      setAPie(new_assetPie);
    }
  }
  // CheckTipBoxes function is used to get index for tipbox on tipsheet_html_segments from Backend
  function CheckTipBoxes(video_duration_int) {
    //New tipbox should be added here
    // Srirag: This implementation requires any additional tip to be manually entered in so the below is a array of all the possible tool tips.
    const tipbox_title = [
      //"Bill Introduction",
      //"Subjects Discussed",
      //"Presenter Information",
      //"Other Bills Mentioned",
      //"Vote Results",
      "Speaker Participation Chart",
      "Quotes",
      "Notes",
    ];
    if (tipbox_title)
          tipbox_title.map((element) => {
            tipbox_title.indexOf(element) > -1 && title_store.push(element);
          });
    if (video_duration_int < 180)       //push signal to skip quote box
      title_store.push("NoQuotes");

    if (aStory.tipsheet_type === "bill")
      title_store.push("Veto Message");
  }

  function CheckAlignmentMeter(assetData) {
    if (assetData) {
      //console.log("Check Alignment Meter: ",assetData);
      let newAlignmentData = [];
      let newOrgs = [];

      assetData.map((item) => {
        if (item.asset_type === "alignment_meter") {
          //alignmentMeterData.push(item);
          newAlignmentData.push(item);
        }
        if (item.caption === "Organization Alignments") {
          if (relevantOrgs.length < 1) {
            Object.keys(item.data).map((k) => {
              //relevantOrgs.push(item.data[k]['Org ID']);
              newOrgs.push(item.data[k]['Org ID']);
            })
          }
        }
      })
      setAlignmentMeterData(newAlignmentData);
      setRelevantOrgs(newOrgs);
      return true;
    }
    return false;
  }

  function HandleTriggerText(tipsheet){
    let base_text = getTriggers(tipsheet, false);
    if (APIPathStory.length > 13 && APIPathStory.substring(3,14) === "information")
      setTriggerText(<Markdown>{base_text}</Markdown>);
    else
      setTriggerText(replaceLegCodes(base_text,tipsheet.personas));
  }

  useEffect(() => {
    Promise.all(
      tags.map((icon) => import(`../../assets/light_gray_icons/${icon}.svg`))
    ).then((svgs) => {
      const finalTags = [];
      svgs.map((svg) => {
        tags.map((icon) => {
          svg.default.includes(icon) && finalTags.push({ svg, icon });
        });
      });

      setAllTags([...finalTags]);
    });
    fetchData();
  }, []);


  useEffect(() => {
    if (alignmentMeterData.length === 0 || relevantOrgs.length === 0) return;
    //console.log('alignmentMeterData: ',alignmentMeterData);
  let pids = Object.keys(alignmentMeterData[0]['data']);
    let allOrgNames = new Set();
    let allLegDatas = [];

    // construct data for AlignmentMeter Google Chart components
    // requires header as first value (["Label", "Value"])
    pids.forEach((pid) => {
      let leg_data = [["Label", "Value"]];
      let leg_name = "";

      for (var id in relevantOrgs) {
        const org_id = relevantOrgs[id];

        if (org_id < 0 && alignmentMeterData[0]['data'][pid][org_id]) {
          var org = alignmentMeterData[0]['data'][pid][org_id][0];
          const value_raw = (Number(parseFloat(org['alignment']).toFixed(1)));
          leg_data.push([org['name'],
            ({v:value_raw, f:value_raw.toString()+'%', totalVotes: org['total_alignment_opportunities']})]);
          if (leg_name.length < 1) {
            leg_name = org['first'] + ' ' + org['last'];
          }
          allOrgNames.add(org['name']);
        }
      }

    allLegDatas.push({leg_name: leg_name, org_alignment: leg_data});
  })

    setAlignmentMeterInfo(allLegDatas);
  let orgNameList = [...allOrgNames]
    setAlignmentMeterOrgs(orgNameList);

}, [alignmentMeterData, relevantOrgs])

  useEffect(() => {
    setInfoGaugeData(infoGaugePrep());
  }, [Personas, aStory.assets, aStory.hearing_transcript])

  //console.log('related: ',aStory['related_tipsheets']);
  return (
    <div className="main-tipsheet-content">
      <S.Layout>
        {<S.SidebarWrap>
          <Sidebar data={aStory} />
        </S.SidebarWrap>}
        <S.DetailView >
          <S.Bar1 >
            <S.TipSheetBox>
              {(aStory.publish_date_str) && (<span className={"publish-date"}>Tip Sheet generated on {aStory.publish_date_str}</span>)}
              <S.Block>
                <S.BoxTitle style={{ fontSize: 20, float: "left" }}>STORY IDEA</S.BoxTitle>
                { /* tipsheetHtml.tag_items.map((ta) => { */
                  aStory.tags?.map((index) => {
                    let ta = index['tagname'];
                    let ta_display = ta.substring(0,3) === "geo"
                        ? ta.substring(3).replace(/_/g,' ').toUpperCase()
                        : ta;

                    return (
                      <S.Span>
                        {ta.toLowerCase() !== "unknown" &&
                          <S.InterestTag>
                            <S.InterestTagIcon src={allTags?.find(t => t.icon === ta.toLowerCase())?.svg.default} />
                            <S.InterestTagLabel>{ta_display}</S.InterestTagLabel>
                          </S.InterestTag>
                        }
                      </S.Span>
                    );
                  })
                }
              </S.Block>
              <S.Headline>
                {aStory.headline_text}
                {(aStory.score || aStory.score === 0)?(" ("+aStory.score.toString()+" points)"):''}
              </S.Headline>
              {(aStory.bill_name?.toLowerCase() !== "no bill discussed") && (
                  <S.Para>{triggerText}
                    {(
                        <div className="information-tip">
                          The story idea above was created using "generative AI," which can make mistakes. You can fact-check this description against the original text in the "Notes on Methodology" section below.
                        </div>
                    )}
                  </S.Para>)}

              <S.SummaryVideo>
                <S.TipSummaryBlock>
                  <S.Para className="floatRight">
                    <TipSheet_Summary
                      committee = {aStory.committee}
                      committee_id = {aStory.committee_id}
                      date_text = {aStory.date_text}
                      authors = {aStory.bill_authors}
                      bill = {aStory.bill_name}
                      bill_tag = {aStory.billname}
                      bill_subject = {aStory.bill_subject}
                      bill_title = {aStory.bill_title}
                      publish_date_str = {aStory.publish_date_str}
                      voteData = {votePartyData}
                      video_duration = {aStory.video_duration}
                      bill_analysis_summary = {aStory.content?.filter((item) => item.phenom === 'bill_analysis_summary')}
                      bill_ai_summary = {aStory.content?.filter((item) => item.phenom === 'bill_ai_summary')}
                      vote_outcome = {aStory.vote_outcome}
                    />
                  </S.Para>

                {(aStory.bill_name?.toLowerCase() === "no bill discussed") && (
                  <S.Para>{triggerText}
                  {(
                    <div className="information-tip">
                      The story idea above was created using "generative AI," which can make mistakes. You can fact-check this description against the original text in the "Notes on Methodology" section below.
                    </div>
                  )}
                  </S.Para>)
                }
                </S.TipSummaryBlock>
                <div className={"summary-right-panel"}>
                  {(aStory.tipsheet_type === "bill") && (aStory.related_tipsheets.length > 1) &&
                    <div className={"tipsheets-table-summary-right-panel"}>
                      <h3>Related Tip Sheet Headlines</h3>
                      <ChartTable options={{pageSize: 6}} dataTable={[['Date','Points','Tip Sheet']].
                          concat(aStory.related_tipsheets?.
                          filter(story => story.id !== aStory.jsonid).
                          map((tipsheet, index) => {
                            const date_segments = tipsheet.tile_date.split('-').map(i => parseInt(i));
                            return [new Date(date_segments[0], date_segments[1] - 1, date_segments[2]),
                              tipsheet.tipsheet_tile_json.score,
                              LinkSimple(tipsheet.tile_title,tipsheet.id,index),
                      ];
                          }))}
                      />
                    </div>
                  }
                  {(aStory.bill_name?.toLowerCase() === "no bill discussed") && (
                      <div className="container">
                        {/*<VideoTranscriptModal storyData={aStory} quoteData={null}>
                        BillDiscussionLinkInfo
                        */}
                        {LinkDD(
                          <div className="video-container">
                            <video width={"100%"} className="video-element" src={aStory?.video && aStory.video[0]}></video>
                            <div className="play-button-overlay">
                              <img className="play-button" src={playIcon}/>
                            </div>
                          </div>,
                            billDiscussionLinkInfo.hearing_id,
                            'hea',null,false,null,
                            '?t='+ billDiscussionLinkInfo.offset + '&f=' + billDiscussionLinkInfo.file_id)
                        }
                        {/*</VideoTranscriptModal>*/}
                      </div>)
                  }
                  {(votePartyData?.length > 0) && (<div className="vote-breakdown-right"><StackedBar data = {votePartyData}/></div>)}
                  <TipSheet_InfoGauges data = {infoGaugeData} />
                </div>
              </S.SummaryVideo>
            </S.TipSheetBox>
          </S.Bar1>

          <S.Bar2>
            {/*
            format/order of the alignmentContributionTableData

            0:"pid"
            1:"full_name"
            2:"first" (now Party)
            3:"last"
            4:"party" (now Vote)
            5:"alignment_score"
            6:"alignment_opp_count"
            7:"legislator_vote"
            8:"name"
            9:"org_position"
            10:"advocating_as"
            11:"donating_as"
            12:"donations"
            13:"contribution_time_period"
            14:"org_success_rate"
            15:"total_donated"
            16:"alignment_time_period"

            */}
            {(aStory.tipsheet_type !== "bill") && (alignmentContributionTableTitle != null) &&
                (alignmentContributionTableData?.length > 1) && (
              <S.TipSheetBox>
                <S.BoxTitle>{alignmentContributionTableTitle[0]}</S.BoxTitle>
                <S.BoxNote>{alignmentContributionTableTitle[1]}</S.BoxNote>
                <BarFormatTable
                    data = {alignmentContributionTableData}
                  formatters = {[{
                        type: "BarFormat",
                        column: 5,
                        options: {
                          width: 200,
                          min: -50,
                          max: 50
                        },
                        maxValue: 100,
                      },
                      {
                        type: "ColorFormat",
                        column: 4,
                        ranges: [
                          [0,1, "white","#386f1f"],
                          [1,2, "white","#b0120a"],
                          [2,null, "white","orange"],
                        ],
                      },
                    ]}
                    chartWrapperParams={{
                      view: { columns: [1, 2, 4, 5, 12] },
                    }}
                    filterColumnIndex={8}
                    filterColumnAttr={"v"}
                    caption={[
                      {
                        index: 9,
                        caption: "Org or Affiliate Position:",
                        styleFn: (val) => {
                          if(val.includes('_if_') || val.includes('_unless_'))
                            return { backgroundColor: "orange", color: "white" };
                          if (val.slice(0,1) === "F")
                            return { backgroundColor: "#386f1f", color: "white" };
                          if (val.slice(0,1) === "A")
                            return { backgroundColor: "#b0120a", color: "white" };
                          return { backgroundColor: "orange", color: "white" };
                        },
                      },
                      { index: 14, attr: "f", caption: "Org and Affiliates Success Rate:"},
                      { index: 15, attr: "f", caption: "Org and Affiliate Total Contributions:" },
                      { index: 8, attr: "org_id", caption: "", componentFn: (val) => {
                          return LinkDD("See more about this org and its affiliates", val, "org",
                              null, false)
                        }
                      },
                    ]}
                />
              </S.TipSheetBox>
            )}

            {/*(title_store.includes("Veto Message")) &&*/
                (aStory.content?.filter(p => p.phenom === "governor_veto_message").length > 0) && (
                    <S.TipSheetBox>
                      <S.BoxTitle>Governor's Veto Message</S.BoxTitle>
                        {aStory.content.filter(p => p.phenom === "governor_veto_message")
                            .map(phenom => {
                              if (phenom.tips && phenom.tips.length > 0 && phenom.tips[0]
                                  && phenom.tips[0].link_url.length > 0) {
                                return <S.BoxNote>
                                  {LinkSimple(phenom.tips[0].data, phenom.tips[0].link_url, null, false)}
                                </S.BoxNote>
                              } else {
                                return <>
                                  <S.BoxNote>This is the veto message pulled directly from state records.</S.BoxNote>
                                    <div className="pullquote veto-message">
                                      {
                                        phenom.tips[0].data
                                            .replace("\n\n", "@")
                                            .split("@")
                                            .map((p, i) => {
                                              return <p key={i}>{p}</p>
                                            })
                                      }
                                    </div>
                                </>
                              }
                            })[0]
                        }

                    </S.TipSheetBox>
              )}
            {(aStory.tipsheet_type === "bill") && false

            }
            {(aStory.tipsheet_type !== "bill") && title_store.includes("NoQuotes") && (
                <S.TipSheetBox>
                  <S.BoxTitle>No Quote Suggestions</S.BoxTitle>
                    <S.BoxNote>
                      Due to the short length of this discussion, there are no good automatic quote suggestions by Tip Sheets.{' '}
                      Please visit the {LinkDD('video and transcript', billDiscussionLinkInfo.hearing_id, 'hea',null,false,null,
                    '?t='+ billDiscussionLinkInfo.offset + '&f=' + billDiscussionLinkInfo.file_id)} for the full discussion.
                    </S.BoxNote>
                </S.TipSheetBox>
            )}
            {title_store.includes("Quotes") && !title_store.includes("NoQuotes") && aStory.pullquotes?.length > 0 && (
              <S.TipSheetBox>
                <S.BoxTitle>Quotes</S.BoxTitle>
                {(aStory.bill_name?.toLowerCase() === "no bill discussed") &&
                    <S.BoxNote>
                      The quotes in this carousel are intended to reflect positions of legislators and those who gave testimony. The quotes are linked to the full transcript for context and alternate quotes.
                    </S.BoxNote>
                }
                {(aStory.bill_name?.toLowerCase() !== "no bill discussed") &&
                    <S.BoxNote>
                      The quotes in this carousel are intended to reflect positions for and against this bill from legislators and those who gave testimony. The quotes are linked to the full transcript for context and alternate quotes.
                    </S.BoxNote>
                }
                <TipSheet_Quotes
                  api_url={API_URL}
                  pullquotes={aStory.pullquotes}
                  onVideoExtracted={handleVideoExtracted}
                  jsonid = {aStory.jsonid}
                  vote_detail={aStory.assets?.filter(asset => asset.id === 'vote_detail')}
                  hearing_transcript={aStory.hearing_transcript}
                  bill_authors={aStory.bill_authors}
                  informational_hearing = {(aStory.bill_name?.toLowerCase() === "no bill discussed")}
                />
              </S.TipSheetBox>
            )}

            {(aStory.tipsheet_type !== "bill") && (Personas != null) && (Personas.length > 0) && (supportersOpponentsData.length > 0) && (
              <S.TipSheetBox>
                <S.BoxTitle>Witnesses</S.BoxTitle>
                {/*<BarChart data = {supportersOpponentsData} />*/}
                  <ChartTable
                    dataTable={Personas}
                    formatters = {[
                      {
                        type: "ColorFormat",
                        column: 3,
                        ranges: [
                          [0,1, "white","#386f1f"],
                          [1,2, "white","#b0120a"],
                          [2,null, "white","orange"],
                        ],
                      }
                    ]}
                  />
              </S.TipSheetBox>
            )}

            {//generic table asset processor -- currently commented out

            aTable.map((table, i) => {
              if(title_store.includes(aTableCaptions[i])) {
                return (
                    <S.TipSheetBox>
                      <S.BoxTitle>{aTableCaptions[i][0]}</S.BoxTitle>
                      <S.BoxNote>{aTableCaptions[i][1]}</S.BoxNote>
                      <ChartTable dataTable={table} options={{pageSize: 10}}/>
                    </S.TipSheetBox>
                );
              }
            })
            }


            {/* Speaker Participation Chart (Pie chart) */
            (aStory.tipsheet_type !== "bill") &&
                title_store.includes("Speaker Participation Chart") &&
              aPie != null && (
                <S.TipSheetBox>
                  <S.BoxTitle>Speaker Participation</S.BoxTitle>
                      <TipSheet_Participant api_url={API_URL}/>
                      <ChartPie dataPie={aPie}/>
                </S.TipSheetBox>
              )}

            {/*(aStory.tipsheet_type === "bill") &&
                <S.TipSheetBox>
                  <S.BoxTitle>Bill Activity this Session</S.BoxTitle>
                  <S.BoxNote>Colored squares represent dates where Digital Democracy produced Tip Sheets related to this
                    bill.
                    Darker means higher points.</S.BoxNote>
                  <div className={"calendar-container"}>
                    <Calendar
                        data={[[{type: "date", id: "Date"}, {type: "number", id: "Headline"},
                          {
                            type: 'string',
                            role: 'tooltip'
                          }]].concat(aStory.related_tipsheets?.map((tipsheet, index) => {
                          const date_segments = tipsheet.tile_date.split('-').map(i => parseInt(i));
                          return [new Date(date_segments[0], date_segments[1] - 1, date_segments[2]),
                            tipsheet.tipsheet_tile_json.score, tipsheet.tile_title + '  (' + tipsheet.tile_date + ' / ' +
                            tipsheet.tipsheet_tile_json.score.toString() + ' points)'];
                        }))}

                        options={{ //title: "Bill Activity",
                          calendar: {cellSize: 11, underYearSpace: 0,},
                          colorAxis: {minValue: 0, colors: ['#FCD70B', '#0e4c60']}
                        }}
                    >
                    </Calendar>
                  </div>
              </S.TipSheetBox>
            */}


            {title_store.includes("General Tips") && (
              <S.TipSheetBox>
                <S.BoxTitle>General Tips</S.BoxTitle>
                <TipSheet_General props = {aStory} />
              </S.TipSheetBox>
            )}

            {title_store.includes("Notes") && (true || billDiscussionLinkInfo.file_id) && (
              <S.TipSheetBox>
                <S.BoxTitle>Notes on Methodology</S.BoxTitle>
                <TipSheetNotes tipsheet = {aStory} bill = {billDiscussionLinkInfo} />
              </S.TipSheetBox>
            )}
          </S.Bar2>
          <S.Bar3>
            {(aStory.tipsheet_type !== "bill") && (aStory.bill_name?.toLowerCase() !== "no bill discussed") && (
            <S.VideoWrap>
              <S.BoxTitle> Video and Transcript </S.BoxTitle>
              <div className="container">
                {/*<VideoTranscriptModal storyData={aStory} quoteData={null}>
                BillDiscussionLinkInfo
                */}
                {LinkDD(
                  <div className="video-container">
                    <video width={"100%"} className="video-element" src={aStory?.video && aStory.video[0]}></video>
                    <div className="play-button-overlay">
                      <img className="play-button" src={playIcon}/>
                    </div>
                  </div>,
                    billDiscussionLinkInfo.hearing_id,
                    'hea',null,false,null,
                    '?t='+ billDiscussionLinkInfo.offset + '&f=' + billDiscussionLinkInfo.file_id)
                }
                {/*</VideoTranscriptModal>*/}
              </div>                                        
            </S.VideoWrap>)}

                {sankeyFinancialData?.length > 1 &&
                    <S.ImageWrap key={1003}>
                      <div>
                        <S.BoxTitle> Financial Relationships </S.BoxTitle>
                          <S.BoxNote>
                            This display is intended to quickly show how much money has been given to each member of this
                            committee (right) by organizations (left) that have taken a position on this bill.
                            The size of the bars is proportionate to the total amount of direct contributions to the legislator
                            and the exact amounts are revealed by hovering on each link. The data is based on available
                            records between 2015 and 2024.
                          </S.BoxNote>

                        <div className={
                          (sankeyFinancialData?.length < 11) ? "sankey-container-small" :
                              (sankeyFinancialData.length < 25) ? "sankey-container-medium" :
                                  (sankeyFinancialData.length < 40) ? "sankey-container-large":
                                      "sankey-container-xlarge"
                        }>
                          <SliderSankey data = {sankeyFinancialData} totalIndex={2} description="Shows the top N organizations by total amount given."/>
                        </div>
                      </div>
                    </S.ImageWrap>
                }


              {/*<S.ImageWrap key={1004}><S.Images>
                <ModalUI
                  style={{
                    height: "600px",
                    borderRadius: "50px",
                  }}
                  showModal={modalIsOpen}
                  onClose={modalSetOpenClose}
                >
                  <S.ImageSliderWrap>
                    <CarouselProvider
                      naturalSlideWidth={300}
                      naturalSlideHeight={300}
                      totalSlides={3}
                    >
                      <S.VideoSlider>
                        <Slide index={0}>
                          <img width={330} src={aStory.photo}></img>
                          <h4></h4>
                        </Slide>
                        <Slide index={1}>
                          <img width={330} src={a}></img>
                          <h4></h4>
                        </Slide>
                        <Slide index={2}>
                          <img width={330} src={b}></img>
                          <h4></h4>
                        </Slide>
                      </S.VideoSlider>
                      <div style={{ textAlign: "center" }}>
                        <ButtonBack className="fas fa-less-than" />
                        <S.Dots>
                          <Dot className="button" slide={0}>
                            1
                          </Dot>
                          <Dot className="button" slide={1}>
                            2
                          </Dot>
                          <Dot className="button" slide={2}>
                            3
                          </Dot>
                        </S.Dots>
                        <ButtonNext className="fas fa-greater-than" />
                      </div>
                    </CarouselProvider>

                    <S.Cancel
                      className="fas fa-times"
                      onClick={() => setModalIsOpen(false)}
                      style={{ cursor: "pointer" }}
                    />
                  </S.ImageSliderWrap>
                </ModalUI>*/}
            {alignmentMeterInfo?.length > 0 && alignmentMeterOrgs?.length > 0 &&
                <S.ImageWrap key={1004}>
                  <AlignmentMeters legOrgAlignmentData={alignmentMeterInfo} orgs={alignmentMeterOrgs}/>
                </S.ImageWrap>
            }

            {aStory.related_tipsheets?.length > 1 &&
                <S.ImageWrap>
                  <S.BoxTitle> Related TipSheets </S.BoxTitle>
                  <S.BoxNote>These tipsheets were created for earlier votes on this same bill.</S.BoxNote>
                  {render_cards(aStory.related_tipsheets.filter(story => story.id !== aStory.jsonid)
                          .toSorted((a,b) => b.tipsheet_tile_json.score - a.tipsheet_tile_json.score),
                      showTooltip,setShowTooltip)}
                  {/*<div className="cards">
                      {aStory.related_tipsheets.filter(story => story.id !== aStory.jsonid).map((card, index) => {
                          return(
                              <Link to={card.id} target="_blank" className="link" key={index}>
                                <div className="Storiescontainer">
                                  <div className="headline">
                                    <div className="featured-card-data date">{card.tile_date_line}</div>
                                    <h3>{card.headline}</h3>
                                    <div className="location"> {card.tile_committee}
                                      | {getDuration(card.tipsheet_tile_json.video_duration)}&nbsp;
                                      | {card.tile_bill_num} {getAuthor(card.tipsheet_tile_json)}
                                      {voteOutcome(card.tipsheet_tile_json, showTooltip, setShowTooltip)}
                                    </div>
                                  </div>
                                  <span className="selection">
                                    {getAnnotatedScore(card.tipsheet_tile_json, showTooltip, setShowTooltip)}
                                  </span>
                                  {tagGraphics(card.tipsheet_tile_json.tags)}
                                </div>
                              </Link>
                          );
                      })}
                  </div>*/}
                </S.ImageWrap>
            }
          </S.Bar3>
        </S.DetailView>
      </S.Layout>
    </div>
  );
}
