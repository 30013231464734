import React, { useState, useEffect, useContext } from 'react';
import HorizonTagsBar from './HorizonTagsBar/HorizonTagsBar';
import tags from '../../assets/Tags';
import sortImg from '../../assets/sorticon.svg';
import { UserContext } from '../../hooks/UserContext';
import Select from 'react-select';
import lawMaker from '../../hooks/lawMaker';
import {
  AddButton,
  ClearButton,
  ExclusionSection,
  FilterBoxes,
  FilterButton,
  FilterDropDown,
  FilterTextBox,
  FilterWrap,
  IconDown,
  IconUp,
  Img,
  LawmakerContainer,
  LawmakerTag,
  LawmakerTags,
  MenuItem,
  SearchButton,
  SmWrap,
  SortButtons,
  SubmitButtonWrapper,
  Tags,
  ToggleButton,
} from './Filter.Styled';

export default function Filter({
  setTags,
  setTimeFilter,
  searchTerm,
  setSearchTerm,
  sortBy,
  setSortBy,
  lawmakers,
  setLawmakers,
  setExclusions,
}) {
  const { user } = useContext(UserContext);
  const [submit, setSubmit] = useState(false);
  const [selectedExclusions, setSelectedExclusions] = useState([]);
  const [userInput, setUserInput] = useState([]);
  const [displayUserSelection, setDisplayUserSelection] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [getLawmakerId, setLawmakerId] = useState(null); // currently selected lawmaker
  const [lawmakeroptions, setLawmakerOptions] = useState([]); // list of all lawmaker options
  const [getLawmakerListState, setLawmakerListState] = useState([]);
  const [getRemoveLawmakerTag, setRemoveLawmakerTag] = useState(-1);
  const { getLawmakersList } = lawMaker(); // get list of lawmakers from database
  const hideInfo = [
    { key: 'appropriations', text: 'Hide Appropriations Hearings' },
    { key: 'floor', text: 'Hide Floor Sessions' },
    { key: 'informational', text: 'Hide Informational Hearings' },
    { key: 'zero', text: 'Hide Tip Sheets with Zero (0) Points' },
  ];

  const StringToArray = (string) => {
    if (string) {
      return string.split(',');
    }
    return [];
  };

  const handleCheckboxClick = (tag) => {
    userInput.includes(tag)
      ? setUserInput(...[userInput.filter((userInput) => userInput !== tag)])
      : setUserInput([...userInput, tag]);
  };

  const handleExclusionsClick = (item) => {
    selectedExclusions.includes(item)
      ? setSelectedExclusions(
          ...[selectedExclusions.filter((exclusion) => exclusion !== item)]
        )
      : setSelectedExclusions([...selectedExclusions, item]);
  };

  // get user tag preferences from context
  useEffect(() => {
    Promise.all(
      tags.map((icon) => import(`../../assets/light_gray_icons/${icon}.svg`))
    ).then(async (svgs) => {
      const finalTags = [];
      svgs.map((svg) => {
        tags.map((icon) => {
          svg.default.includes(icon) && finalTags.push({ svg, icon });
        });
      });
      await setAllTags([...finalTags]);
      if (user) {
        let user_interests = StringToArray(user.user_interests.toLowerCase());
        await setUserInput(user_interests);
      }
    });
  }, [user]);

  // get list of all lawmaker options from API
  useEffect(() => {
    async function fetchLawmakers() {
      let lawmakersList = await getLawmakersList();
      setLawmakerListState(lawmakersList);
    }

    fetchLawmakers();
  }, []);

  useEffect(() => {
    async function mapLawmakers() {
      let options = [];
      getLawmakerListState?.map((lawmaker) => {
        options.push({
          value: lawmaker.pid,
          label:
            lawmaker.first +
            ' ' +
            lawmaker.last +
            ' (' +
            lawmaker.party[0] +
            ')',
        });
      });
      setLawmakerOptions(options);
    }

    mapLawmakers();
  }, [getLawmakerListState]);

  // populate initial with profile-set lawmakers
  useEffect(() => {
    let lawmakers = JSON.parse(user.user_lawmakers);
    if (lawmakers && lawmakers.length > 0) {
      setLawmakers(lawmakers);
    }
  }, []);

  useEffect(() => {
    if (getRemoveLawmakerTag !== -1) {
      setLawmakers(lawmakers.filter((e) => e.pid !== getRemoveLawmakerTag));
      setRemoveLawmakerTag(-1);
    }
  }, [getRemoveLawmakerTag]);

  useEffect(() => {
    displayTags(false);
  }, [userInput]);

  const displayTags = (isshown = true) => {
    if (isshown) {
      setSubmit(!submit);
    } else {
      setSubmit(false);
    }

    const container = [];
    let tags = [];
    allTags.forEach((t) => {
      userInput.forEach((u) => {
        if (t.icon.toUpperCase() === u.toUpperCase()) {
          container.push(t);
          tags.push(t.icon.toUpperCase());
        }
      });
    });

    setDisplayUserSelection(container);
    setTags(tags);
    setExclusions(selectedExclusions);
  };

  const clearTags = () => {
    setUserInput([]);
    setDisplayUserSelection([]);
    setTags([]);
    setSelectedExclusions([]);
    setTimeFilter(0);
  };

  const handleSubmit = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setSearchTerm(e.target.value);
    }
  };

  const handleClearButton = (e) => {
    setSearchTerm('');
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const AddSelectedLawmaker = () => {
    if (!getLawmakerId) return;
    let exist = false;
    lawmakers.forEach((tag) => {
      if (getLawmakerId.value === tag.pid) exist = true;
    });
    if (!exist) {
      getLawmakerListState.forEach((lawmaker) => {
        if (getLawmakerId.value === lawmaker.pid) {
          const obj = {
            pid: lawmaker.pid,
            first: lawmaker.first,
            last: lawmaker.last,
            party: lawmaker.party,
          };
          const listlaw = [...lawmakers, obj];
          setLawmakers(listlaw);
        }
      });
    }
    setLawmakerId(null);
  };

  const style1 = {
    control: (base, state) => ({
      ...base,
      border: '1px solid #ced4da !important',
      boxShadow: '1px solid #ced4da !important',
      '&:hover': {
        border: '1px solid #ced4da !important',
      },
      backgroundColor: '#0e4c60',
      minHeight: '30px',
      height: '30px',
      width: '211px',
    }),
    indicatorsContainer: (prevStyle, state) => ({
      ...prevStyle,
      height: '30px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      color: 'white',
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
  };

  return (
    <>
      <FilterWrap>
        <SmWrap>
          <FilterButton onClick={() => setSubmit(!submit)}>
            Filter by
            {submit ? (
              <IconUp className="fas fa-sort-up" />
            ) : (
              <IconDown className="fas fa-sort-down" />
            )}
          </FilterButton>
          <FilterTextBox
            placeholder="Keywords"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleSubmit}
          />
          {/* commenting out the clear button --FOAAD
            <S.FilterClearButton onClick={handleClearButton}>
              Clear
            </S.FilterClearButton>*/}
          {submit && (
            <FilterDropDown>
              <span style={{ color: 'white' }}>Tags:</span>
              <FilterBoxes>
                {allTags.map(({ svg, icon }, i) => {
                  return (
                    <MenuItem key={i}>
                      <input
                        type="checkbox"
                        checked={userInput.includes(icon)}
                        onChange={() => {
                          handleCheckboxClick(icon);
                        }}
                        id="option"
                        name="option"
                        style={{ margin: '0px' }}
                      />
                      <Img
                        name={icon}
                        src={svg.default}
                        active={userInput.includes(icon)}
                      />
                      <Tags active={userInput.includes(icon)}>
                        {icon === 'public_safety'
                          ? 'Public Safety'
                          : icon.substring(0, 3) === 'geo'
                          ? icon.substring(3).replace(/_/g, ' ').toUpperCase()
                          : icon[0].toUpperCase() + icon.substring(1)}
                      </Tags>
                    </MenuItem>
                  );
                })}
              </FilterBoxes>

              {/*<div
                    style={{
                      color: "white",
                      justifySelf: "center",
                    }}
                >
                  -------------------
                </div>*/}
              <span style={{ color: 'white' }}>Exclusions:</span>
              <ExclusionSection>
                {hideInfo?.length > 0 &&
                  hideInfo.map((hideItem, i) => (
                    <MenuItem key={i}>
                      <input
                        type="checkbox"
                        checked={selectedExclusions.includes(hideItem.key)}
                        onChange={() => {
                          handleExclusionsClick(hideItem.key);
                        }}
                        id="option"
                        name="option"
                        style={{ margin: '0px', 'background-color': 'yellow' }}
                      />
                      <Tags
                        active={selectedExclusions.includes(hideItem.key)}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {hideItem.text}
                      </Tags>
                    </MenuItem>
                  ))}
              </ExclusionSection>
              {/*<span style={{ color: "white" }}>TipSheets generated within the last:</span>
              {timeFilterOptions.map((numDays) => (
                <S.MenuItem>
                  <input
                    type="radio"
                    value={numDays}
                    checked={timeFilter === numDays}
                    onChange={(e) => {
                      setTimeFilter(Number(e.target.value));
                    }}
                    id="option"
                    name="option"
                    style={{ margin: "0px" }}
                  />
                  <S.Tags style={{ whiteSpace: "nowrap" }}>{`${numDays} Days (or latest 30 TipSheets)`}</S.Tags>
                </S.MenuItem>
              ))}
              */}
              {/* Commenting out the State Filters (CA and FL) --FOAAD
              <S.StateTags>
                <S.StateTagsWrap>
                  <input
                    type="checkbox"
                    checked={isChecked2}
                    onChange={(event) =>
                      setIsChecked2(event.currentTarget.checked)
                    }
                    id="option"
                    name="option"
                    style={{ margin: "0px" }}
                  />
                  <S.Img
                    name={CA}
                    src={CA}
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <div style={{ color: "#b7b7b8" }}>California</div>
                </S.StateTagsWrap>
                <S.StateTagsWrap>
                  <input
                    type="checkbox"
                    onChange={(event) =>
                      setIsChecked(event.currentTarget.checked)
                    }
                    checked={isChecked}
                    id="option"
                    name="option"
                    style={{ margin: "0px" }}
                  />
                  <S.Img
                    name={FL}
                    src={FL}
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <div style={{ color: "#b7b7b8" }}>Florida</div>
                </S.StateTagsWrap>
              </S.StateTags>
              */}
              <SubmitButtonWrapper>
                <SearchButton className="button" onClick={() => displayTags()}>
                  Search
                </SearchButton>
                <ClearButton className="button" onClick={() => clearTags()}>
                  Clear
                </ClearButton>
              </SubmitButtonWrapper>
            </FilterDropDown>
          )}
        </SmWrap>
        <SortButtons>
          <img src={sortImg} height="30px" alt="sort by" />
          <ToggleButton
            active={sortBy === 'score'}
            onClick={() => setSortBy('score')}
          >
            Score
          </ToggleButton>
          <ToggleButton
            active={sortBy === 'chrono'}
            onClick={() => setSortBy('chrono')}
          >
            Date
          </ToggleButton>
        </SortButtons>
        <HorizonTagsBar
          dataFromParent={displayUserSelection}
          dataFromParent2={allTags}
          handleCheckboxClick={handleCheckboxClick}
        />
        {/* Commenting out "my collection" --FOAAD
        <S.SelectionButton>My Collection</S.SelectionButton>
        */}
        <LawmakerContainer>
          <Select
            value={getLawmakerId}
            options={lawmakeroptions}
            styles={style1}
            placeholder="Filter by legislator"
            onChange={setLawmakerId}
            isClearable={true}
          />
          <AddButton
            onClick={function () {
              AddSelectedLawmaker();
            }}
          >
            Add
          </AddButton>
        </LawmakerContainer>
        {lawmakers.length > 0 ? (
          <LawmakerTags>
            {lawmakers.map((tag, i) => {
              return (
                <LawmakerTag>
                  <span
                    className="tag-remove"
                    onClick={function () {
                      setRemoveLawmakerTag(tag.pid);
                    }}
                  ></span>
                  <span className="tag-content">
                    {tag.first[0]}. {tag.last} ({tag.party[0]})
                  </span>
                </LawmakerTag>
              );
            })}
          </LawmakerTags>
        ) : null}
      </FilterWrap>
    </>
  );
}
